import React, { createContext, useState } from "react";

const QuestionDatabaseContext = createContext();

export const QuestionDatabaseContextProvider = ({ children }) => {
  const [levelOne, setLevelOne] = useState(0);
  const [levelTwoA, setLevelTwoA] = useState(0);
  const [levelTwoB, setLevelTwoB] = useState(0);

  return (
    <QuestionDatabaseContext.Provider
      value={{
        levelOne,
        levelTwoA,
        levelTwoB,
        setLevelOne,
        setLevelTwoA,
        setLevelTwoB,
      }}
    >
      {children}
    </QuestionDatabaseContext.Provider>
  );
};

export default QuestionDatabaseContext;
