import React, { useEffect, useState } from "react";
import getLogger from "../util/getLogger";
import runningInBrowser from "../util/runningInBrowser";
import inDevelopment from "../util/inDevelopment";
import inStaging from "../util/inStaging";
import inProduction from "../util/inProduction";
import { initialize } from "launchdarkly-js-client-sdk";
import inTesting from "../util/inTesting";

const logger = getLogger("FeatureFlagContext");

const FeatureFlagContext = React.createContext();

export const FeatureFlagContextProvider = (props) => {
  const [flags, setFlags] = useState({});
  const [flagsReady, setFlagsReady] = useState(false);

  useEffect(() => {
    if (runningInBrowser()) {
      let launchDarklyClientId;
      if (inTesting()) {
        const testFlags = window.localStorage.getItem("featureFlags");
        logger.debug("testFlags", testFlags);
        if (testFlags) {
          setFlags(JSON.parse(testFlags));
        }
      } else if (inDevelopment()) {
        launchDarklyClientId = "603acc49aa934e11a6e7d7c4";
      } else if (inStaging()) {
        launchDarklyClientId = "603acc2f68a2a111cc2887cd";
      } else if (inProduction()) {
        launchDarklyClientId = "603ac9af5af502108a0e5d93";
      }

      if (launchDarklyClientId) {
        logger.info("initializing LaunchDarkly");
        const ldclient = initialize(launchDarklyClientId, { key: "anonymous" });

        ldclient.on("ready", () => {
          logger.debug("LaunchDarkly ready", ldclient.allFlags());

          setFlags(ldclient.allFlags());
          setFlagsReady(true);
        });

        ldclient.on("change", (theChange) => {
          logger.debug("got a change from LaunchDarkly", theChange);

          const flagName = Object.keys(theChange)[0];
          const value = theChange[flagName].current;

          setFlags((prevFlags) => ({ ...prevFlags, [flagName]: value }));
        });
      }
    }
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        flags,
        flagsReady,
      }}
    >
      {props.children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagContext;
