import React, { createContext, useEffect, useState } from "react";
import getLogger from "../util/getLogger";

const logger = getLogger("DeviceContext");

const DeviceContext = createContext();

export const DeviceContextProvider = ({ children }) => {
  const [audioDevices, setAudioDevices] = useState([]);
  const [audioDeviceId, setAudioDeviceId] = useState(null);

  const [videoDevices, setVideoDevices] = useState([]);
  const [videoDeviceId, setVideoDeviceId] = useState(null);

  const [explicitlyMuted, setExplicitlyMuted] = useState(false);
  const [explicitlyStoppedVideo, setExplicitlyStoppedVideo] = useState(false);

  const [muted, setMuted] = useState(false);
  const [videoStopped, setVideoStopped] = useState(false);

  useEffect(() => {
    enumerateDevices();
  }, []);

  const enumerateDevices = async () => {
    logger.debug("Enumerating devices");
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();

    const foundAudioDevices = mediaDevices.filter(
      (mediaDevice) => mediaDevice.kind === "audioinput"
    );
    logger.debug("List of audio devices");
    logger.debug(foundAudioDevices);

    setAudioDevices(foundAudioDevices);

    const foundVideoDevices = mediaDevices.filter(
      (mediaDevice) => mediaDevice.kind === "videoinput"
    );
    logger.debug("List of video devices");
    logger.debug(foundVideoDevices);

    setVideoDevices(foundVideoDevices);

    if (localStorage.getItem("audioDeviceId")) {
      if (
        foundAudioDevices.find(
          (device) => device.deviceId === localStorage.getItem("audioDeviceId")
        )
      ) {
        setAudioDeviceId(localStorage.getItem("audioDeviceId"));
      }
    } else if (foundAudioDevices.length > 0) {
      const foundAudioDeviceId = foundAudioDevices[0].deviceId;
      logger.info(`Setting initial video device to ${foundAudioDeviceId}`);
      setAudioDeviceId(foundAudioDeviceId);
    }

    if (localStorage.getItem("videoDeviceId")) {
      if (
        foundVideoDevices.find(
          (device) => device.deviceId === localStorage.getItem("videoDeviceId")
        )
      ) {
        setVideoDeviceId(localStorage.getItem("videoDeviceId"));
      }
    } else if (foundVideoDevices.length > 0) {
      const foundVideoDeviceId = foundVideoDevices[0].deviceId;
      logger.info(`Setting initial video device to ${foundVideoDeviceId}`);
      setVideoDeviceId(foundVideoDeviceId);
    }
  };

  const setAudioDeviceIdWrapped = (audioDeviceId) => {
    logger.info(`Changing video device to ${audioDeviceId}`);

    localStorage.setItem("audioDeviceId", audioDeviceId);
    setAudioDeviceId(audioDeviceId);
  };

  const setVideoDeviceIdWrapped = (videoDeviceId) => {
    logger.info(`Changing video device to ${videoDeviceId}`);

    localStorage.setItem("videoDeviceId", videoDeviceId);
    setVideoDeviceId(videoDeviceId);
  };

  return (
    <DeviceContext.Provider
      value={{
        audioDevices,
        audioDeviceId,
        videoDevices,
        videoDeviceId,
        muted,
        videoStopped,
        explicitlyMuted,
        explicitlyStoppedVideo,
        setAudioDeviceId: setAudioDeviceIdWrapped,
        setVideoDeviceId: setVideoDeviceIdWrapped,
        setMuted,
        setVideoStopped,
        setExplicitlyMuted,
        setExplicitlyStoppedVideo,
        enumerateDevices,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceContext;
