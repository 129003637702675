export const BRAND_PRIMARY = "#4e1f8d";
export const BRAND_SECONDARY = "#8263ae";

export const TEXT_DARK = "#2E2C31";
export const TEXT_DEFAULT = "#4A464F";
export const TEXT_LIGHT = "#898592";
export const TEXT_OFF_WHITE = "#DADADA";

export const TEXT_LIGHT_DISABLED = "rgba(255, 255, 255, 0.5)";

export const HIGHLIGHT_PRIMARY = "#7EC6DC";
export const HIGHLIGHT_PRIMARY_LIGHT = "#A2D8E9";
export const HIGHLIGHT_SECONDARY = "#F3D56A";
export const HIGHLIGHT_TERTIARY = "#F8B5B2";
export const HIGHLIGHT_QUATERNARY = "#F5B659";

export const PLATFORM_ERROR = "#DC5353";
export const PLATFORM_ERROR_LIGHT = "#E86F6F";
export const PLATFORM_SUCCESS = "#5BC69F";
export const PLATFORM_DISABLE = "#E0E0E0";

export const ELEMENT_DARKER = "rgba(0, 0, 0, 0.75)";
export const ELEMENT_DARK = "rgba(0, 0, 0, 0.5)";
export const ELEMENT_DEFAULT = "rgba(0, 0, 0, 0.3)";
export const ELEMENT_LIGHT = "rgba(0, 0, 0, 0.12)";
export const ELEMENT_OFF_WHITE_DARK = "rgba(255, 255, 255, 0.7)";
export const ELEMENT_OFF_WHITE = "rgba(255, 255, 255, 0.8)";
export const ELEMENT_OFF_WHITE_LIGHT = "rgba(255, 255, 255, 0.95)";

export const WHITE = "#fff";

export const TRANSPARENT = "rgba(0, 0, 0, 0.0)";
