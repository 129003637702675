import styled from "styled-components";
import { BRAND, BRAND_LIGHT } from "../colors";

export const aStyle = `
  color: ${BRAND};
  text-decoration: none;
`;

export const aHoverStyle = `
  cursor: pointer;
  color: ${BRAND_LIGHT};
  text-decoration: underline;
`;

export default styled.a`
  ${aStyle}

  :hover {
    ${aHoverStyle}
  }
`;
