import runningInBrowser from "./runningInBrowser";

export default () => {
  if (runningInBrowser()) {
    if (window.Cypress) {
      return true;
    }
  }
  return false;
};
