import styled from "styled-components";
import Button from "./Button";
import { aStyle, aHoverStyle } from "../A";

const LinkButton = styled(Button)`
  border: none;
  padding: 0;
  background: none;
  ${aStyle}

  :hover {
    box-shadow: none;
    ${aHoverStyle}
  }

  ${({ disabled }) =>
    disabled &&
    `
    :hover {
      text-decoration: none;
    }
  `}
`;

export default LinkButton;
