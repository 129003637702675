import runningInBrowser from "./runningInBrowser";

export default () => {
  if (runningInBrowser()) {
    if (window.location.href.startsWith("http://localhost")) {
      return "http://localhost:8000";
    } else if (
      window.location.href.startsWith("https://staging-app.hedgehogtrivia.com")
    ) {
      return "https://staging-api.hedgehogtrivia.com";
    }
  }

  return "https://api.hedgehogtrivia.com";
};
