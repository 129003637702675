import React, { useEffect } from "react";
import { UserContextProvider } from "../contexts/UserContext";
import { Grommet } from "grommet";
import styled, { createGlobalStyle } from "styled-components";
import LogRocket from "logrocket";
import inDevelopment from "../util/inDevelopment";
import getLogger from "../util/getLogger";
import runningInBrowser from "../util/runningInBrowser";
import { REGULAR_TEXT } from "../colors";
import { Helmet } from "react-helmet";
import Pusher from "pusher-js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TestContextProvider } from "../contexts/TestContext";
import { DeviceContextProvider } from "../contexts/DeviceContext";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import inTesting from "../util/inTesting";
import { FeatureFlagContextProvider } from "../contexts/FeatureFlagContext";
import { BrowserContextProvider } from "../contexts/BrowserContext";
import { QuestionDatabaseContextProvider } from "../contexts/QuestionDatabaseContext";
import { Logger } from "twilio-video";
import { RtcBaseContextProvider } from "../contexts/RtcBaseContext";
import { tippyTooltipGlobalStyles } from "../v2/components/elements/tooltips/TippyTooltip";

const logger = getLogger("App");

const twilioLogger = Logger.getLogger("twilio-video");
twilioLogger.setLevel("info");

// let ErrorBoundary = styled.div``;

if (runningInBrowser() && !inDevelopment() && !inTesting()) {
  Bugsnag.start({
    apiKey: "654eb70ea32d8f8aa1b5f4bf01e6f9c3",
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.VERCEL_URL, // eslint-disable-line
    releaseStage: process.env.STAGE, // eslint-disable-line
  });
}

// comment this out when done testing bugsnag
// if (runningInBrowser() && inDevelopment()) {
//   Bugsnag.start({
//     apiKey: "654eb70ea32d8f8aa1b5f4bf01e6f9c3",
//     plugins: [new BugsnagPluginReact()],
//     releaseStage: "development", // eslint-disable-line
//   });
// }

// if (inDevelopment()) {
Pusher.logToConsole = true;
// }

const theme = {
  global: {
    font: {
      family: "Rubik",
      size: "18px",
      height: "20px",
    },
  },
};

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  
  body {
    height: 100%;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    color: ${REGULAR_TEXT};
  }
  
  #___gatsby {
    height: 100%;
  }
  
  #gatsby-focus-wrapper {
    height: 100%;
  }
  
  .__react_component_tooltip {
    max-width: 300px;
  }
  
  ${tippyTooltipGlobalStyles}
`;

const Wrapper = styled.div`
  height: 100%;
`;

export default function App({ element }) {
  useEffect(() => {
    if (window.EventSource && !inDevelopment()) {
      LogRocket.init("bp7p6j/trivia", {
        console: {
          isEnabled: {
            log: true,
            debug: true,
            info: true,
            warn: true,
            error: true,
          },
        },
      });
    }
  }, []);

  logger.info("Rendering App");

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <GlobalStyle />
      <Grommet theme={theme} themeMode="dark" style={{ height: "100%" }}>
        <TestContextProvider>
          <FeatureFlagContextProvider>
            <BrowserContextProvider>
              <RtcBaseContextProvider>
                <UserContextProvider>
                  <QuestionDatabaseContextProvider>
                    <DeviceContextProvider>
                      <DndProvider backend={HTML5Backend}>
                        {element}
                      </DndProvider>
                    </DeviceContextProvider>
                  </QuestionDatabaseContextProvider>
                </UserContextProvider>
              </RtcBaseContextProvider>
            </BrowserContextProvider>
          </FeatureFlagContextProvider>
        </TestContextProvider>
      </Grommet>
    </Wrapper>
  );
}
