export const WHITE = "#fff";

// grommet colors
export const BRAND = "#7d4cdb";
export const BRAND_LIGHT = "#9060eb";

export const ACCENT_1 = "#6fffb0";
export const ACCENT_3 = "#81fced";
export const ACCENT_4 = "#ffca58";

// export const NEUTRAL_2 = "#3d138d";
export const NEUTRAL_2 = "#2f1b57";

export const LIGHT_GREY_1 = "#f8f8f8";
export const LIGHT_GREY_2 = "#f2f2f2";
export const LIGHT_GREY_3 = "#ededed";
export const LIGHT_GREY_4 = "#dadada";

export const DARK_GREY_1 = "#333";
export const DARK_GREY_2 = "#555";
export const DARK_GREY_3 = "#777";
export const DARK_GREY_4 = "#999";

export const STATUS_CRITICAL = "#ff4040";
export const STATUS_WARNING = "#ffaa15";
export const STATUS_OK = "#00c781";
export const STATUS_UNKNOWN = "#ccc";

// our names
export const BRAND_PRIMARY = "#4e1f8d";
export const BRAND_SECONDARY = "#8263ae";

export const REGULAR_TEXT = DARK_GREY_1;
export const LIGHT_REGULAR_TEXT = LIGHT_GREY_1;
export const MUTED_TEXT = DARK_GREY_3;
export const LIGHT_MUTED_TEXT = LIGHT_GREY_3;
export const STATUS_CRITICAL_TEXT = "#17221c";
export const DROP_HIGHLIGHT = ACCENT_3;

export const LIGHT_SEETHROUGH_TEXT = "rgba(255,255,255,70%)";

export const ACCENT_PRIMARY = "#7EC6DC";
export const ACCENT_SECONDARY = "#F3D56A";
export const ACCENT_TERTIARY = "#F8B5B2";

export const OVERLAY_1 = "rgba(0, 0, 0, 20%)";
export const OVERLAY_2 = "rgba(0, 0, 0, 15%)";
export const OVERLAY_3 = "rgba(0, 0, 0, 10%)";
export const OVERLAY_4 = "rgba(0, 0, 0, 5%)";

export const DARK_OVERLAY_1 = "rgba(0, 0, 0, 30%)";
export const DARK_OVERLAY_2 = "rgba(0, 0, 0, 40%)";
export const DARK_OVERLAY_3 = "rgba(0, 0, 0, 50%)";
export const DARK_OVERLAY_4 = "rgba(0, 0, 0, 60%)";

export const TEST = "#3D138D";
