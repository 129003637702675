import styled from "styled-components";
import { ACCENT_1, BRAND, REGULAR_TEXT, WHITE } from "../../colors";

const Button = styled.button`
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  font: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0;
  background: ${WHITE};
  overflow: visible;
  text-transform: none;
  border: 2px solid ${BRAND};
  border-radius: 18px;
  color: ${REGULAR_TEXT};
  padding: 4px 22px;
  font-size: 18px;
  line-height: 24px;
  -webkit-transition-property: color, background-color, border-color, box-shadow;
  transition-property: color, background-color, border-color, box-shadow;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  ${({ size }) =>
    size === "large" &&
    `
    font-size: 22px;
    line-height: 28px;
    padding: 8px 32px;
    border-radius: 24px;
  `}

  :hover {
    box-shadow: 0 0 0 2px ${BRAND};
  }

  :focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${ACCENT_1};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
    cursor: default;
    
    :hover {
      box-shadow: none;
    }
  `}
`;

export default Button;
