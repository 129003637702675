/* global process */

export function isRunningInStorybook() {
  try {
    if (process.env.STORYBOOK) return true;
  } catch {
    // A ReferenceError will be thrown if process is undefined
  }

  return false;
}
