// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-create-edit-event-group-js": () => import("./../../../src/pages/create-edit-event-group.js" /* webpackChunkName: "component---src-pages-create-edit-event-group-js" */),
  "component---src-pages-create-edit-event-js": () => import("./../../../src/pages/create-edit-event.js" /* webpackChunkName: "component---src-pages-create-edit-event-js" */),
  "component---src-pages-create-edit-question-js": () => import("./../../../src/pages/create-edit-question.js" /* webpackChunkName: "component---src-pages-create-edit-question-js" */),
  "component---src-pages-create-edit-question-template-js": () => import("./../../../src/pages/create-edit-question-template.js" /* webpackChunkName: "component---src-pages-create-edit-question-template-js" */),
  "component---src-pages-create-edit-quiz-js": () => import("./../../../src/pages/create-edit-quiz.js" /* webpackChunkName: "component---src-pages-create-edit-quiz-js" */),
  "component---src-pages-create-edit-round-js": () => import("./../../../src/pages/create-edit-round.js" /* webpackChunkName: "component---src-pages-create-edit-round-js" */),
  "component---src-pages-create-edit-round-template-js": () => import("./../../../src/pages/create-edit-round-template.js" /* webpackChunkName: "component---src-pages-create-edit-round-template-js" */),
  "component---src-pages-create-edit-tiebreaker-js": () => import("./../../../src/pages/create-edit-tiebreaker.js" /* webpackChunkName: "component---src-pages-create-edit-tiebreaker-js" */),
  "component---src-pages-create-edit-tiebreaker-template-js": () => import("./../../../src/pages/create-edit-tiebreaker-template.js" /* webpackChunkName: "component---src-pages-create-edit-tiebreaker-template-js" */),
  "component---src-pages-detect-js": () => import("./../../../src/pages/detect.js" /* webpackChunkName: "component---src-pages-detect-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-event-list-js": () => import("./../../../src/pages/event-list.js" /* webpackChunkName: "component---src-pages-event-list-js" */),
  "component---src-pages-host-js": () => import("./../../../src/pages/host.js" /* webpackChunkName: "component---src-pages-host-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-event-js": () => import("./../../../src/pages/join-event.js" /* webpackChunkName: "component---src-pages-join-event-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-play-quiz-js": () => import("./../../../src/pages/play-quiz.js" /* webpackChunkName: "component---src-pages-play-quiz-js" */),
  "component---src-pages-preview-av-js": () => import("./../../../src/pages/preview-av.js" /* webpackChunkName: "component---src-pages-preview-av-js" */),
  "component---src-pages-preview-round-template-js": () => import("./../../../src/pages/preview-round-template.js" /* webpackChunkName: "component---src-pages-preview-round-template-js" */),
  "component---src-pages-preview-tiebreaker-template-js": () => import("./../../../src/pages/preview-tiebreaker-template.js" /* webpackChunkName: "component---src-pages-preview-tiebreaker-template-js" */),
  "component---src-pages-question-database-js": () => import("./../../../src/pages/question-database.js" /* webpackChunkName: "component---src-pages-question-database-js" */),
  "component---src-pages-quiz-list-js": () => import("./../../../src/pages/quiz-list.js" /* webpackChunkName: "component---src-pages-quiz-list-js" */),
  "component---src-pages-select-round-template-js": () => import("./../../../src/pages/select-round-template.js" /* webpackChunkName: "component---src-pages-select-round-template-js" */),
  "component---src-pages-select-tiebreaker-template-js": () => import("./../../../src/pages/select-tiebreaker-template.js" /* webpackChunkName: "component---src-pages-select-tiebreaker-template-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */)
}

