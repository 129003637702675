import React, { createContext, useEffect, useContext, useState } from "react";
import getLogger from "../util/getLogger";
import FeatureFlagContext from "./FeatureFlagContext";
// import RtcBase from "@rtcbase/rtcbase";

const logger = getLogger("RtcBaseContext");

const RtcBaseContext = createContext();

export const RtcBaseContextProvider = (props) => {
  const { flags, flagsReady } = useContext(FeatureFlagContext);
  const [isRtcBaseEnabled, setIsRtcBaseEnabled] = useState(false);

  useEffect(() => {
    if (flagsReady) {
      logger.debug("Got flags");

      logger.info("RtcBase temporarily removed from code");
      setIsRtcBaseEnabled(false);

      // if (flags["RtcBase"]) {
      //   logger.info("Initializing RtcBase");
      //   const RTCBASE_APP_ID = "AIzaSyDFmon4ZLDSzmzf4tho88OdIbFqhT8HfHo";
      //   RtcBase.init(RTCBASE_APP_ID);
      //
      //   setIsRtcBaseEnabled(true);
      // } else {
      //   logger.info("Not initializing RtcBase");
      // }
    } else {
      logger.debug("Waiting for flags");
    }
  }, [flagsReady, flags]);

  return (
    <RtcBaseContext.Provider value={{ isRtcBaseEnabled }}>
      {props.children}
    </RtcBaseContext.Provider>
  );
};

export default RtcBaseContext;
