import runningInBrowser from "./runningInBrowser";

export default () => {
  if (runningInBrowser()) {
    if (
      window.location.href.startsWith("http://localhost") ||
      window.location.href.startsWith("http://10.0.2.2")
    ) {
      return true;
    }
  }

  return false;
};
