import React, { createContext } from "react";
import { fromJS } from "immutable";
import getLogger from "../util/getLogger";

const logger = getLogger("UserContext");

const initState = {
  announcement: null,
  currentUser: null,
};

const UserContext = createContext(initState);

export class UserContextProvider extends React.Component {
  state = initState;

  setFormat = (format) => {
    this.setState({
      format: fromJS(format),
    });
  };

  setAnnouncement = (announcement) => {
    this.setState({
      announcement,
    });
  };

  resetState = () => {
    this.setState(initState);
  };

  setCurrentUser = (user) => {
    this.setState({ currentUser: user });
  };

  render() {
    logger.debug("rendering");

    const { currentUser, announcement } = this.state;

    return (
      <UserContext.Provider
        value={{
          currentUser,
          announcement,
          setCurrentUser: this.setCurrentUser,
          setAnnouncement: this.setAnnouncement,
          resetState: this.resetState,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserContext;
