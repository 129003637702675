import React from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import App from "../components/App";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <App element={element} />
  </ApolloProvider>
);
