import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { createGlobalStyle, css } from "styled-components";
import { PARAGRAPH_SUBHEADING_BOLD } from "../../../typography";
import { TEXT_DARK, WHITE } from "../../../colors";
import { isRunningInStorybook } from "../../../util/isRunningInStorybook";

// TODO: check to see if performance warrants this
export const tippyTooltipGlobalStyles = css`
  div.tippy-box {
    ${PARAGRAPH_SUBHEADING_BOLD}
    color: ${WHITE};
    background-color: ${TEXT_DARK};
    border-radius: 50px;
    border-color: ${TEXT_DARK};
    text-align: center;
  }
  
  div.tippy-content {
    padding: 4px 8px;
  }
  
  div.tippy-arrow {
    color: ${TEXT_DARK};
  }
`;

const TippyTooltipGlobalStyle = createGlobalStyle`
  ${tippyTooltipGlobalStyles}
`;

const runningInStorybook = isRunningInStorybook();

const TippyTooltip = ({ content, visible, ...props }) => {
  return (
    <>
      {runningInStorybook && <TippyTooltipGlobalStyle />}
      <Tippy content={content} visible={visible} {...props} />
    </>
  );
};

export default TippyTooltip;
