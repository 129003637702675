import React, { createContext, useState, useRef } from "react";
import getLogger from "../util/getLogger";

const logger = getLogger("TestContext");

const TestContext = createContext();

export const TestContextProvider = (props) => {
  logger.info("rendering");
  let shadowState = null;

  const [state, setState] = useState(null);
  const ref = useRef(null);

  logger.info(`render state: ${state}`);
  logger.info(`render shadowState: ${shadowState}`);
  logger.info(`render ref: ${ref.current}`);

  const updateState = (value) => {
    logger.info(`Updating state to: ${value}`);
    shadowState = value;
    setState(value);
    ref.current = value;
  };

  const checkState = () => {
    logger.info(`checkState: ${state}`);
    logger.info(`checkShadowState: ${shadowState}`);
    setState((currentState) => {
      logger.info(`in setState: ${currentState}`);
      return currentState;
    });
    logger.info(`checkRef: ${ref.current}`);
  };

  return (
    <TestContext.Provider
      value={{ state, refState: ref.current, updateState, checkState }}
    >
      {props.children}
    </TestContext.Provider>
  );
};

export default TestContext;
