import React, { useEffect, useState } from "react";
import getBrowserInfo from "../util/getBrowserInfo";
import styled from "styled-components";
import LinkButton from "./buttons/LinkButton";

const Wrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  line-height: 120%;
  text-align: center;
  margin: 0 30px 20px;
`;

const Message = styled.div`
  max-width: 500px;
  line-height: 150%;
  margin: 0 30px 20px;
  text-align: center;
`;

const UnsupportedBrowser = () => {
  const [info, setInfo] = useState({});
  const [showingInfo, setShowingInfo] = useState(false);

  const handleShowBrowserInfo = () => setShowingInfo(true);
  const handleHideBrowserInfo = () => setShowingInfo(false);

  useEffect(() => {
    const info = getBrowserInfo();
    setInfo(info);
  }, []);

  return (
    <Wrapper>
      <Title>Unsupported Browser</Title>
      <Message>
        The only browser that is currently capable of providing a high quality
        audio/video experience today is a recent version of Chrome running on a
        Mac or Windows laptop/desktop or a Chromebook.
      </Message>
      {!showingInfo && (
        <LinkButton onClick={handleShowBrowserInfo}>
          Show browser info
        </LinkButton>
      )}
      {showingInfo && (
        <>
          <LinkButton onClick={handleHideBrowserInfo}>
            Hide browser info
          </LinkButton>
          <pre>{JSON.stringify(info, null, 2)}</pre>
        </>
      )}
    </Wrapper>
  );
};

export default UnsupportedBrowser;
