import runningInBrowser from "./runningInBrowser";

export default () => {
  if (runningInBrowser()) {
    if (
      window.location.href.startsWith("https://staging-app.hedgehogtrivia.com")
    ) {
      return true;
    }
  }

  return false;
};
