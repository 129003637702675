import { css } from "styled-components";

export const PARAGRAPH_NORMAL = css`
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-align: left;
`;

export const PARAGRAPH_NORMAL_BOLD = css`
  ${PARAGRAPH_NORMAL}
  font-weight: 500;
`;

export const PARAGRAPH_SMALLER = css`
  ${PARAGRAPH_NORMAL}
  font-size: 13px;
`;

export const PARAGRAPH_SMALL = css`
  ${PARAGRAPH_NORMAL}
  font-size: 14px;
`;
export const PARAGRAPH_SMALL_BOLD = css`
  ${PARAGRAPH_SMALL}
  font-weight: 500;
`;

export const PARAGRAPH_SUBHEADING = css`
  ${PARAGRAPH_NORMAL}
  font-size: 11px;
`;

export const PARAGRAPH_SUBHEADING_BOLD = css`
  ${PARAGRAPH_SUBHEADING}
  font-weight: 500;
  font-size: 12px;
`;

export const PARAGRAPH_SUBHEADING_UPPERCASE = css`
  ${PARAGRAPH_NORMAL}
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
`;

export const PARAGRAPH_LARGE_BOLD = css`
  ${PARAGRAPH_NORMAL}
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
`;

export const BUTTON_LARGE = css`
  ${PARAGRAPH_NORMAL}
  font-size: 16px;
`;

export const TITLE_HEADER = css`
  ${PARAGRAPH_NORMAL}
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
`;

export const TITLE_HERO = css`
  ${PARAGRAPH_NORMAL}
  font-weight: 500;
  font-size: 42px;
  line-height: 120%;
`;

export const TITLE_GIGANTIC = css`
  ${PARAGRAPH_NORMAL}
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
`;
