import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import "isomorphic-fetch"; // See https://docs.logrocket.com/docs/troubleshooting-sessions
import getApiPrefix from "../util/getApiPrefix";

const fetcher = (...args) => {
  return window.fetch(...args);
};

const getUri = () => `${getApiPrefix()}/graphql`;

const httpLink = new HttpLink({
  uri: getUri(),
  credentials: "include",
  mode: "cors",
  fetch: fetcher,
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      // this is the one that is triggered by useQuery
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});
